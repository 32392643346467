import request from '@/utils/request'

// 查询用户提现记录列表
export function listUserWithdraw(query) {
  return request({
    url: '/platform/user-withdraw/list',
    method: 'get',
    params: query
  })
}

// 查询用户提现记录分页
export function pageUserWithdraw(query) {
  return request({
    url: '/platform/user-withdraw/page',
    method: 'get',
    params: query
  })
}

// 查询用户提现记录详细
export function getUserWithdraw(data) {
  return request({
    url: '/platform/user-withdraw/detail',
    method: 'get',
    params: data
  })
}

// 查询用户提现记录详细
export function getUserAccount(data) {
  return request({
    url: '/platform/user-account/detail',
    method: 'get',
    params: data
  })
}


// 新增用户提现记录
export function addUserWithdraw(data) {
  return request({
    url: '/platform/user-withdraw/add',
    method: 'post',
    data: data
  })
}

// 修改用户提现记录
export function updateUserWithdraw(data) {
  return request({
    url: '/platform/user-withdraw/edit',
    method: 'post',
    data: data
  })
}

// 删除用户提现记录
export function delUserWithdraw(data) {
  return request({
    url: '/platform/user-withdraw/delete',
    method: 'post',
    data: data
  })
}
